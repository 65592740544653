import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import ElementUI from 'element-ui'
import i18n from './lang/index'
import 'element-ui/lib/theme-chalk/index.css';
// 全局公共样式
import './styles/common/common.css'
// import locale from 'element-ui/lib/locale/lang/zh-TW.js'
// import lang_en from 'element-ui/lib/locale/lang/en.js'
// // import locale from 'element-ui/lib/locale'

// Vue.use(ElementUI, { locale })
// locale.use(lang_en)

import {
	get,
	post,
	put,
	del,
	options,
	head,
	patch
} from './http/http.js'

Vue.config.devtools = process.env.VUE_APP_ENV === 'dev' || process.env.VUE_APP_ENV === 'stage'
Vue.config.silent = process.env.VUE_APP_ENV === 'prod'
Vue.config.productionTip = false

Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value)
})

// 挂载各种全局请求方法，使用this.$get()...请求
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$del = del;
Vue.prototype.$options = options;
Vue.prototype.$head = head;
Vue.prototype.$patch = patch;
Vue.prototype.$formatDate = function(val){
	if (!val) {
		return ''
	}
	const date = new Date(val);
	// 格式化日期
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	// 组装成指定格式的日期字符串
	return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

Vue.prototype.$formatDateDMY = function(val){
	if (!val) {
		return ''
	}
	const date = new Date(val);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${day}/${month}/${year}`;
}

Vue.prototype.$formatDateMY = function(val){
	if (!val) {
		return ''
	}
	const date = new Date(val);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	return `${month}/${year}`;
}

export default new Vue({
	router,
	i18n,
	store,
	render: h => h(App),
}).$mount('#app')